@import "../../node_modules/bootstrap/scss/_functions";
@import "../../node_modules/bootstrap/scss/_variables";
@import "../../node_modules/bootstrap/scss/_media";
@import "../../node_modules/bootstrap/scss/_mixins";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "../../node_modules/bootstrap/scss/bootstrap";
@import "../../node_modules/hamburgers/dist/hamburgers.css";
@import "../../node_modules/leaflet/dist/leaflet.css";
@use "../../node_modules/leaflet/dist/images";

@font-face {
  font-family: 'IBM Plex Serif';
  src: url('../fonts/IBMPlexSerif.woff2') format('woff2'),
  url('../fonts/IBMPlexSerif.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url('../fonts/IBMPlexSerif-Bold.woff2') format('woff2'),
  url('../fonts/IBMPlexSerif-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Serif';
  src: url('../fonts/IBMPlexSerif-Medium.woff2') format('woff2'),
  url('../fonts/IBMPlexSerif-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Medium.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans-Bold.woff2') format('woff2'),
  url('../fonts/IBMPlexSans-Bold.woff') format('woff');
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'IBM Plex Sans';
  src: url('../fonts/IBMPlexSans.woff2') format('woff2'),
  url('../fonts/IBMPlexSans.woff') format('woff');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


body, html {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

.intro {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 450;
  font-size: 22px;
  line-height: 29px;
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Serif, serif;
    font-style: normal;
    font-weight: normal;
    font-size: 55px;
    line-height: 66px;

  }
}

.mt-240 {
  @include media-breakpoint-up(lg) {
    margin-top: 240px;
  }
}

.mt-180 {
  @include media-breakpoint-up(lg) {
    margin-top: 180px;
  }
  margin-top: 100px;
}

#map {

  height: 370px;
}

.container {
  @include media-breakpoint-up(lg) {
    padding-left: 60px;
    padding-right: 0;

  }
}

.contact-text-title {
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Serif, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;

    margin-left: 150px;
  }
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
}

.lg-screen .title-menu {
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  line-height: 31px;
  margin-bottom: 39px;
  text-align: center;
}
.footer-menu{
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  bottom: 5%;
}
.responsive-screen {

}

.responsive-screen .title-menu {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
  color: #636363;
  margin-bottom: 2rem;
}

.row {
  @include media-breakpoint-down(lg) {
    margin-right: 0px;
    margin-left: 0px
  }
}

.contact-text {
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    color: #000000;
    margin-left: 150px;
  }
  font-family: IBM Plex Sans, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

.px-100 {
  @include media-breakpoint-up(lg) {

    padding-left: 100px;
    padding-right: 100px;
  }
}

.text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 21px;
  text-align: justify;
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Sans, sans-serif;
    font-size: 24px;
    font-style: normal;
    font-weight: 400;
    line-height: 31px;
    letter-spacing: 0;
    text-align: justify;
  }
}

.mt-30 {
  margin-top: 30px;
}

.mt-100 {
  margin-top: 100px;

}

.mb-96 {
  margin-bottom: 6rem;
}

.mb-120 {
  @include media-breakpoint-up(lg) {
    margin-bottom: 7.5rem;
    }
  }

  .mb-320 {
    margin-bottom: 10rem;
    @include media-breakpoint-up(lg) {
      margin-bottom: 20rem;

}
}

.title {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 40px;
  line-height: 52px;
  text-align: left;
  color: #636363;
  margin-top: 3.75rem;
  margin-bottom: 0.7rem;
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Serif, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 75px;
    line-height: 97px;
    text-align: left;
    color: #636363;
    padding-bottom: 32px;
  }
}

.padding-privacy {
  padding-top: 30px;
  @include media-breakpoint-up(lg) {
    padding-top: 50px;
}
}

p.privacy-text {
  margin-bottom: 9px;
}

.pb-240 {

  padding-bottom: 150px;
  @include media-breakpoint-up(lg) {
    padding-bottom: 240px;
}
}

.privacy-text {
  font-family: IBM Plex Sans;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 21px;
  text-align: justify;
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 24px;
    line-height: 31px;
    text-align: justify;
  }
}

.sub-title {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: black;
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Serif, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 47px;
    color: black;
  }
}

h4 {

  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 26px;
  color: #636363;
  @include media-breakpoint-up(lg) {
    margin-top: 50px;
    font-family: IBM Plex Serif, serif;
    font-style: normal;
    font-weight: 600;
    font-size: 30px;
    line-height: 39px;
    color: #636363;
  }
}

img.logo {
  height: 50px;
  margin-bottom: 20px;
  @include media-breakpoint-up(lg) {
    padding-top: 50px;
    height: 100px;
  }
}

.sender {
  position: absolute;
  width: 238px;
  height: 49px;
  left: 0;
  top: 0;

}

.bg-gray {
  background-color: #e6e6e6;
  @include media-breakpoint-down(lg) {
  margin-left: -15px;
  margin-right: -15px;
}
}

.redirect {
  text-decoration: none;
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: justify;

  color: #636363;
}

.footer {
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  text-align: center;

  width: 100%;
  height: 150px;
  background-color: #F2002A;
  @include media-breakpoint-up(lg) {
    font-family: IBM Plex Sans, sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 23px;

  }
  color: #FFFFFF;
}

.footer a {
  text-decoration: none;
  color: white;
}

.management-name {
  margin-top: 20px;
  font-family: IBM Plex Sans, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 30px;
  line-height: 39px;
  text-align: center;
}

.management-work {
  font-family: IBM Plex Serif, serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 23px;
  text-align: center;

  margin-top: -10px;
  color: #636363;
}

.sender-button {
  width: 240px;
  height: 50px;
  border-radius: 15px;
  background-color: #636363;
  transition-duration: 300ms;
  color: white;
}

.sender-button:hover {
  background-color: #F2002A;
  transition-duration: 300ms;
}

.anchor a {
  text-decoration: none;
  color: black;
  font-family: IBM Plex Sans, sans-serif;
  font-size: 24px;
  font-style: normal;
  font-weight: 400;
  line-height: 31px;
  letter-spacing: 0;
  text-align: left;

  :hover {
    font-weight: 600 !important;
    font-family: IBM Plex Serif, serif;

  }
}

.main-menu {
  height: 100%;
  width: 82%;
  position: fixed;
  top: 0;
  right: 0;
  background-color: #FFFFFF;
  z-index: 1030;
  transform: translate(100%);
  transition: all .05s linear;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.isOpened {
  transform: translateY(0) !important;
}

.anchor {
  padding-left: 0px;
  margin-right: -50px;
  @media (max-width: 1160px) {
    margin-left: -35px;
  }

    margin-top: 20px;


}

.main-anchor {

  display: none;
  @include media-breakpoint-up(lg) {
    display: contents;
  }
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
height: 3px;
}
img.lazy {
  opacity: 0;
}

img:not(.initial) {
  transition: opacity 1s;
}

img.initial,
img.loaded,
img.error {
  opacity: 1;
}

img:not([src]) {
  visibility: hidden;
}

